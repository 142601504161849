import './side-panel';

const $electricForm = document.querySelector('.side-panel .side-panel-electric');
const $nameInput = document.querySelector('.side-panel .side-panel-electric .first-name-input');
const $lastNameInput = document.querySelector('.side-panel .side-panel-electric .last-name-input');
const $emailInput = document.querySelector('.side-panel .side-panel-electric .courriel-input');
const $phoneInput = document.querySelector('.side-panel .side-panel-electric .phone-input');
const $carInput = document.querySelector('.side-panel .side-panel-electric .car-input');
const $btnElectricSubmit = document.querySelector('.side-panel .side-panel-electric .btn-submit-electric');

if ($electricForm) {
    // Enabled submit button if first name, last name and message are not empty
    const updateSubmitButton = () => {
        if ($nameInput.value.trim() !== '' && $lastNameInput.value.trim() !== '' && $emailInput.value.trim() !== '' && $phoneInput.value.trim() !== '' && $carInput.value.trim() !== '') {
            $btnElectricSubmit.removeAttribute('disabled');
        } else {
            $btnElectricSubmit.setAttribute('disabled', 'true');
        }
    };

    const inputs = [$nameInput, $lastNameInput, $emailInput, $phoneInput, $carInput];
    inputs.forEach((input) => {
        input.addEventListener('input', updateSubmitButton);
    });
}
