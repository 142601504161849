import { up, down } from 'slide-element';

const $accordionWrappers = document.querySelectorAll('.accordion-wrapper');

$accordionWrappers.forEach(($wrapper) => {
    const $button = $wrapper.querySelector('.accordion-button');
    const $panel = $wrapper.querySelector('.accordion-panel');

    $button.addEventListener('click', () => {
        const isExpanded = $button.getAttribute('aria-expanded') === 'true';

        $button.setAttribute('aria-expanded', !isExpanded);
        $panel.setAttribute('aria-hidden', isExpanded);

        if (isExpanded) {
            up($panel);
        } else {
            down($panel);
        }
    });
});
