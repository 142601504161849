import Swiper from 'swiper';
import {
    Navigation, Pagination, EffectCards, Autoplay,
} from 'swiper/modules';

const swiper3d = document.querySelectorAll('.swiper-3d-cards');

swiper3d.forEach((element) => {
    new Swiper(element, {
        modules: [Navigation, Pagination, EffectCards, Autoplay],
        effect: 'cards',
        direction: 'vertical',
        keyboard: true,
        loop: true,
        noSwiping: false,
        noSwipingClass: '.swiper-wrapper',
        pagination: {
            el: element.querySelector('.swiper-pagination'),
        },
        navigation: {
            nextEl: element.querySelector('.swiper-button-next'),
            prevEl: element.querySelector('.swiper-button-prev'),
        },
        cardsEffect: {
            perSlideOffset: 10,
            perSlideRotate: 0,
            rotate: false,
            slideShadows: false,
        },
        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
        },
    });
});
