/* eslint-disable func-names, max-len, wrap-iife, no-inner-declarations */
(function () {
    const isSmallScreen = window.matchMedia('(max-width: 767px)').matches;
    const touchDevice = window.matchMedia('(pointer: coarse)').matches;
    if (isSmallScreen || touchDevice) {
        const StackCards = function (element) {
            this.element = element;
            this.items = this.element.getElementsByClassName('js-stack-cards__item');
            this.scrollingFn = false;
            this.scrolling = false;
            initStackCardsEffect(this);
            initStackCardsResize(this);
        };

        const currentCard = document.querySelectorAll('.cards-indicator span');
        const cardsHeader = document.querySelector('.block-services-header');

        function initStackCardsEffect(element) { // use Intersection Observer to trigger animation
            setStackCards(element); // store cards CSS properties
            const observer = new IntersectionObserver(stackCardsCallback.bind(element), { threshold: [0, 1] });
            observer.observe(element.element);
        }

        function initStackCardsResize(element) { // detect resize to reset gallery
            element.element.addEventListener('resize-stack-cards', () => {
                setStackCards(element);
                animateStackCards.bind(element);
            });
        }

        function stackCardsCallback(entries) { // Intersection Observer callback
            if (entries[0].isIntersecting) {
                if (this.scrollingFn) return; // listener for scroll event already added
                stackCardsInitEvent(this);
            } else {
                if (!this.scrollingFn) return; // listener for scroll event already removed
                window.removeEventListener('scroll', this.scrollingFn);
                this.scrollingFn = false;
            }
        }

        function stackCardsInitEvent(element) {
            element.scrollingFn = stackCardsScrolling.bind(element);
            window.addEventListener('scroll', element.scrollingFn);
        }

        function stackCardsScrolling() {
            if (this.scrolling) return;
            this.scrolling = true;
            window.requestAnimationFrame(animateStackCards.bind(this));
        }

        function setStackCards(element) {
            // store wrapper properties
            element.marginY = getComputedStyle(element.element).getPropertyValue('--stack-cards-gap');
            getIntegerFromProperty(element); // convert element.marginY to integer (px value)
            element.elementHeight = element.element.offsetHeight;

            // store card properties
            const cardStyle = getComputedStyle(element.items[0]);
            element.cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue('top')));
            element.cardHeight = Math.floor(parseFloat(cardStyle.getPropertyValue('height')));

            // store window property
            element.windowHeight = window.innerHeight;

            // reset margin + translate values
            if (Number.isNaN(element.marginY)) {
                element.element.style.paddingBottom = '0px';
            } else {
                element.element.style.paddingBottom = `${element.marginY * (element.items.length - 1)}px`;
            }

            for (let i = 0; i < element.items.length; i += 1) {
                if (Number.isNaN(element.marginY)) {
                    element.items[i].style.transform = 'none;';
                } else {
                    element.items[i].style.transform = `translateY(${element.marginY * i}px)`;
                }
            }
        }

        function getIntegerFromProperty(element) {
            const node = document.createElement('div');
            node.setAttribute('style', `opacity:0; visibility: hidden; position: absolute; height: ${element.marginY}`);

            element.element.appendChild(node);
            element.marginY = parseInt(getComputedStyle(node).getPropertyValue('height'), 10);
            element.element.removeChild(node);
        }

        function animateStackCards() {
            if (Number.isNaN(this.marginY)) { // --stack-cards-gap not defined - do not trigger the effect
                this.scrolling = false;
                return;
            }

            const { top } = this.element.getBoundingClientRect();

            if (this.cardTop - top + this.element.windowHeight - this.elementHeight - this.cardHeight + this.marginY + this.marginY * this.items.length > 0) {
                this.scrolling = false;
                return;
            }

            for (let i = 0; i < this.items.length; i += 1) {
                const scrolling = this.cardTop - top - i * (this.cardHeight + this.marginY);

                if (scrolling > 0) {
                    const scaling = i === this.items.length - 1 ? 1 : (this.cardHeight - scrolling * 0.05) / this.cardHeight;

                    // Remove "active" class from the previous span
                    if (i > 0) {
                        currentCard[i - 1].classList.remove('active');
                    }

                    // Add "active" class to the current span
                    currentCard[i].classList.add('active');

                    this.items[i].style.transform = `translateY(${this.marginY * i}px) scale(${scaling})`;

                    // If last card is stack hide title
                    if (i === this.items.length - 1) {
                        cardsHeader.classList.add('hidden');
                    } else {
                        cardsHeader.classList.remove('hidden');
                    }
                } else {
                    this.items[i].style.transform = `translateY(${this.marginY * i}px)`;
                    currentCard[i].classList.remove('active');
                }
            }
            this.scrolling = false;
        }

        // initialize StackCards object
        const stackCards = document.getElementsByClassName('js-stack-cards');
        const intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);

        if (stackCards.length > 0 && intersectionObserverSupported) {
            const stackCardsArray = [];
            for (let i = 0; i < stackCards.length; i += 1) {
                (function () {
                    stackCardsArray.push(new StackCards(stackCards[i]));
                })();
            }

            let resizingId = false;
            const customEvent = new CustomEvent('resize-stack-cards');

            window.addEventListener('resize', () => {
                clearTimeout(resizingId);
                resizingId = setTimeout(doneResizing, 500);
            });

            function doneResizing() {
                for (let i = 0; i < stackCardsArray.length; i += 1) {
                    (function () {
                        stackCardsArray[i].element.dispatchEvent(customEvent);
                    })();
                }
            }
        }
    }
}());
