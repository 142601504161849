import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

const swiperCards = document.querySelectorAll('.block-partners-list');
const btnPrev = document.querySelector('.block-partners .swiper-button-prev');
const btnNext = document.querySelector('.block-partners .swiper-button-next');

swiperCards.forEach((element) => {
    new Swiper(element, {
        modules: [Navigation, Autoplay],
        slidesPerView: 3,
        centeredSlides: true,
        autoplay: {
            delay: 1500,
            pauseOnMouseEnter: true,
        },
        loop: true,
        navigation: {
            nextEl: btnPrev,
            prevEl: btnNext,
        },
        breakpoints: {
            640: {
                slidesPerView: 5,
            },
            1024: {
                slidesPerView: 8,
            },
        },
    });
});
