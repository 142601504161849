import anime from 'animejs/lib/anime';
import inView from 'in-view';
import animateCountUp from 'countup-animation/dist/index';

const easingType = 'easeOutQuart';
const isMobile = window.matchMedia('(pointer: coarse)').matches;
const isDesktop = window.matchMedia('(min-width: 768px)').matches;

// Hero home
inView('.hero-home').once('enter', () => {
    anime({
        targets: '.hero-visual',
        opacity: ['0', '100%'],
        duration: 750,
        easing: 'easeOutCubic',
        complete: () => {
            document.querySelector('.hero-home').classList.add('animation-complete');
            anime({
                targets: ['.hero-home .hero-title', '.hero-home .hero-description', '.hero-home .hero-cta', '.hero-advantages-brokers'],
                translateY: ['40%', '0%'],
                opacity: ['0', '100%'],
                delay: anime.stagger(250),
                easing: easingType,
            });
        },
    });
});

// Block services
if (!isMobile || isDesktop) {
    const cardsServiceAnimation = () => {
        const cardsService = document.querySelectorAll('.card-service');
        cardsService.forEach((element) => {
            // Mouse enter (hover start)
            element.addEventListener('mouseenter', () => {
                anime({
                    targets: element,
                    scale: 1.05,
                    duration: 150,
                    easing: 'spring(1, 60, 10, 0)',
                });
            });

            // Mouse leave (hover end)
            element.addEventListener('mouseleave', () => {
                anime({
                    targets: element,
                    scale: 1,
                    duration: 150,
                    easing: 'spring(1, 60, 10, 0)',
                });
            });
        });
    };

    inView('.block-services').once('enter', () => {
        anime({
            targets: '.block-services .card-service',
            translateY: ['20%', '0%'],
            opacity: ['0', '100%'],
            delay: anime.stagger(150),
            easing: easingType,
            complete: () => {
                cardsServiceAnimation();
            },
        });
    });
}

// Block promos
const cardsPromoAnimations = () => {
    const cardsPromo = document.querySelectorAll('.card-promo');

    cardsPromo.forEach((element) => {
        // Mouse enter (hover start)
        element.addEventListener('mouseenter', () => {
            anime({
                targets: element,
                scale: 1.02,
                duration: 150,
                easing: 'spring(1, 60, 10, 0)',
            });
        });

        // Mouse leave (hover end)
        element.addEventListener('mouseleave', () => {
            anime({
                targets: element,
                scale: 1,
                duration: 150,
                easing: 'spring(1, 60, 10, 0)',
            });
        });
    });
};

inView('.block-promos').once('enter', () => {
    anime({
        targets: '.block-promos .card-promo',
        translateY: ['50%', '0%'],
        opacity: ['0', '100%'],
        delay: anime.stagger(150),
        easing: easingType,
        complete: () => {
            cardsPromoAnimations();
        },
    });
});

// Block stats
inView('.block-stats').once('enter', () => {
    anime({
        targets: '.block-stats .stats-secondary',
        translateY: ['50%', '0%'],
        opacity: ['0', '100%'],
        delay: anime.stagger(150),
        easing: easingType,
    });
});
const targets = document.querySelectorAll('.block-stats');
let observer;

function handleIntersection(entries) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const countupEls = entry.target.querySelectorAll('.countup');
            countupEls.forEach((el, index) => {
                setTimeout(() => {
                    animateCountUp(el, 3500, 1, 0);
                }, index * 100);
            });
            observer.unobserve(entry.target);
        }
    });
}
// Observe the container div
observer = new IntersectionObserver(handleIntersection);
targets.forEach((target) => observer.observe(target));

// Block swiper
inView('.block-swiper').once('enter', () => {
    anime({
        targets: ['.block-swiper .block-title-wrapper', '.block-swiper .swiper'],
        translateY: ['50%', '0%'],
        opacity: ['0', '100%'],
        delay: anime.stagger(150),
        easing: easingType,
    });
});

// Block swiper cards
inView('.block-swiper-cards').once('enter', () => {
    anime({
        targets: '.block-swiper-cards-visual',
        translateY: ['50%', '0%'],
        opacity: ['0', '100%'],
        delay: anime.stagger(150),
        easing: easingType,
    });
});

// Footer
const footerTrigger = isMobile ? 'footer' : '.footer-legals';

inView(footerTrigger).once('enter', () => {
    anime({
        targets: 'footer .socials a',
        translateY: ['50%', '0%'],
        opacity: ['0', '100%'],
        delay: anime.stagger(150),
        easing: easingType,
    });
});

inView.threshold(0.5);
