import { lock, unlock } from 'tua-body-scroll-lock';
import clickOutside from 'click-outside';
import { createFocusTrap } from 'focus-trap';
import Cookies from 'js-cookie';

const ELECTRIC_SIDEPANEL_HASH_TRIGGER = 'formulaire-vehicule-electrique';

const $btnContact = document.querySelectorAll('.btn-contact');
const $btnJobs = document.querySelectorAll('.btn-job');
const $btnElectric = document.querySelectorAll('.btn-electric');
const $btnPrograms = document.querySelectorAll('.btn-programs');
const $ctaElectric = document.querySelector('[data-cta-electric]');
const $sidePanel = document.querySelector('.side-panel');
const $contactSidePanel = document.querySelector('.side-panel-contact');
const $jobSidePanel = document.querySelector('.side-panel-job');
const $electricSidePanel = document.querySelector('.side-panel-electric');
const $programsSidePanel = document.querySelector('.side-panel-programs');
const $offCanvasInner = document.querySelector('.side-panel .offcanvas-inner');
const $btnClose = document.querySelector('.side-panel .btn-close');
const $jobTitle = document.getElementById('job-title');
const $jobIdInput = document.getElementById('job-id');
const $jobPosition = document.getElementById('job-position');

let focusTrap;
let unbindClickOutside;
let isOpen = false;

function toggle() {
    !isOpen ? open() : close();
}
function open() {
    isOpen = true;
    $sidePanel.style.display = null;
    setTimeout(() => {
        $sidePanel.classList.add('is-open');
        lock($offCanvasInner, { overflowType: 'clip' });
        focusTrap = createFocusTrap($sidePanel, {
            clickOutsideDeactivates: true,
            preventScroll: true,
        });
        focusTrap.activate();
        window.addEventListener('keydown', onEscape);
        unbindClickOutside = clickOutside($offCanvasInner, close);
    }, 1);
}
function close() {
    isOpen = false;
    $sidePanel.classList.remove('is-open');
    setTimeout(() => {
        $sidePanel.style.display = 'none';
        unlock($offCanvasInner);
        focusTrap.deactivate();
        window.removeEventListener('keydown', onEscape);
        unbindClickOutside();
        $sidePanel.querySelectorAll('.offcanvas-body .wpcf7-form').forEach((form) => {
            form.style.removeProperty('display');
        });
        $sidePanel.querySelectorAll('.offcanvas-body .confirmation-block').forEach((confirmation) => {
            confirmation.style.display = 'none';
        });
    }, 300);
}
function onEscape(event) {
    if (event.key === 'Escape') {
        close();
        window.removeEventListener('keydown', onEscape);
    }
}

if ($sidePanel) {
    $btnClose.addEventListener('click', () => {
        close();
    });
}

if ($btnContact) {
    $btnContact.forEach((button) => {
        button.addEventListener('click', () => {
            toggle();
            $contactSidePanel.classList.add('is-open');
            $jobSidePanel.classList.remove('is-open');
            $electricSidePanel.classList.remove('is-open');
        });
    });
}

if ($btnJobs) {
    $btnJobs.forEach((button) => {
        button.addEventListener('click', () => {
            const dataTitleValue = button.getAttribute('data-title');
            const formattedTitle = dataTitleValue ? ` - ${dataTitleValue}` : '';
            const dataIdValue = button.getAttribute('data-id');
            const dataPositionsValue = button.getAttribute('data-categories');
            $jobTitle.innerHTML = formattedTitle;
            $jobIdInput.value = dataIdValue;
            if (dataPositionsValue) {
                const positions = JSON.parse(dataPositionsValue);
                $jobPosition.innerHTML = '';
                for (let i = 0; i < positions.length; i += 1) {
                    const opt = document.createElement('option');
                    opt.value = positions[i].ca_name;
                    opt.innerHTML = positions[i].ca_name;
                    $jobPosition.appendChild(opt);
                }
                $jobPosition.closest('label').style.display = 'block';
            } else {
                $jobPosition.closest('label').style.display = 'none';
                $jobPosition.innerHTML = '';
            }
            $jobSidePanel.classList.add('is-open');
            $contactSidePanel.classList.remove('is-open');
            $electricSidePanel.classList.remove('is-open');
            toggle();
        });
    });
}

const openElectricSidePanel = () => {
    toggle();
    $electricSidePanel.classList.add('is-open');
    $contactSidePanel.classList.remove('is-open');
    $jobSidePanel.classList.remove('is-open');
};

if ($btnElectric) {
    $btnElectric.forEach((button) => {
        button.addEventListener('click', openElectricSidePanel);
    });
}

const openProgramsSidePanel = () => {
    toggle();
    $programsSidePanel.classList.add('is-open');
    $electricSidePanel.classList.remove('is-open');
    $contactSidePanel.classList.remove('is-open');
    $jobSidePanel.classList.remove('is-open');
};

if ($btnPrograms) {
    $btnPrograms.forEach((button) => {
        button.addEventListener('click', openProgramsSidePanel);
    });
}

window.addEventListener('load', () => {
    if ($ctaElectric && window.location.hash === `#${ELECTRIC_SIDEPANEL_HASH_TRIGGER}`) {
        $ctaElectric.scrollIntoView({ behavior: 'smooth' });
        openElectricSidePanel();
    }

    // Programmes
    const urlParams = new URLSearchParams(window.location.search);
    const typeProgramme = urlParams.get('typeProgramme');
    const $productsSelect = document.querySelector('.products-select-input');

    if (typeProgramme && $productsSelect) {
        const openPanel = () => {
            Array.from($productsSelect.options).some((option) => {
                if (option.value.replace(/\s+/g, '') === typeProgramme.replace(/\s+/g, '')) {
                    $productsSelect.value = option.value;
                    openProgramsSidePanel();
                    return true;
                }
                return false;
            });
        };

        if (!Cookies.get('telio_cc_cookie')) {
            window.addEventListener('cc:onFirstConsent', () => {
                openPanel();
            });
        } else {
            openPanel();
        }
    }
});
