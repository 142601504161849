import { lock, unlock } from 'tua-body-scroll-lock';
import { up, down } from 'slide-element';
import clickOutside from 'click-outside';
import { createFocusTrap } from 'focus-trap';

let focusTrap;
let unbindClickOutside;
let isOpen = false;

const $btnMenu = document.querySelector('.header .btn-menu');
const $btnClose = document.querySelector('.header .btn-close');
const $offCanvas = document.querySelector('.header .menu-offcanvas');
const $dropdownWrappers = document.querySelectorAll('.nav-primary .dropdown-wrapper');

$btnMenu.addEventListener('click', toggle);
$btnClose.addEventListener('click', close);

$dropdownWrappers.forEach(($dropdown) => {
    const $trigger = $dropdown.querySelector('.dropdown-trigger');
    const $menu = $dropdown.querySelector('.dropdown-menu');
    let dropdownOpened = false;

    $trigger.addEventListener('click', () => {
        if (!dropdownOpened) {
            dropdownOpened = true;
            $trigger.classList.add('is-open');
            down($menu);
        } else {
            dropdownOpened = false;
            $trigger.classList.remove('is-open');
            up($menu);
        }
    });
});

function toggle() {
    !isOpen ? open() : close();
}
function open() {
    isOpen = true;
    $offCanvas.style.display = null;
    setTimeout(() => {
        $offCanvas.classList.add('is-open');
        lock($offCanvas, { overflowType: 'clip' });
        focusTrap = createFocusTrap($offCanvas, {
            clickOutsideDeactivates: true,
            preventScroll: true,
        });
        focusTrap.activate();
        window.addEventListener('keydown', onEscape);
        unbindClickOutside = clickOutside($offCanvas, close);
    }, 1);
}
function close() {
    isOpen = false;
    $offCanvas.classList.remove('is-open');
    setTimeout(() => {
        $offCanvas.style.display = 'none';
        unlock($offCanvas);
        focusTrap.deactivate();
        window.removeEventListener('keydown', onEscape);
        unbindClickOutside();
    }, 300);
}
function onEscape(event) {
    if (event.key === 'Escape') {
        close();
        window.removeEventListener('keydown', onEscape);
    }
}
