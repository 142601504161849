import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const swiperCards = document.querySelectorAll('.block-swiper-cards-items');

swiperCards.forEach((element) => {
    new Swiper(element, {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 'auto',
        spaceBetween: 16,
        centeredSlides: false,
        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
        },
        loop: true,
        navigation: {
            nextEl: element.querySelector('.swiper-button-next'),
            prevEl: element.querySelector('.swiper-button-prev'),
        },
        pagination: {
            el: element.querySelector('.block-swiper-cards-pagination'),
        },
        breakpoints: {
            640: {
                spaceBetween: 16,
            },
        },
    });
});
