import Lenis from '@studio-freight/lenis';

const lenis = new Lenis();
// Hide navigation
lenis.scrollTo('.hero-realisation', {
    offset: window.innerWidth < 768 ? -10 : -30,
    easing: 'easeInOutCubic',
    lock: true,
    lerp: 0.1,
});
function raf(time) { // eslint-disable-line no-inner-declarations
    lenis.raf(time);
    requestAnimationFrame(raf);
}
requestAnimationFrame(raf);
