/* eslint-disable */
/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
import * as CookieConsent from "vanilla-cookieconsent";
import Cookies from 'js-cookie';

// Check for 'telio_cc_cookie' and 'typeProgramme' query string
const urlParams = new URLSearchParams(window.location.search);
const typeProgramme = urlParams.get('typeProgramme');

CookieConsent.run({
    cookie: {
        domain: window.consent_cookie_domain,
        name: 'telio_cc_cookie',
    },
    disablePageInteraction: !Cookies.get('telio_cc_cookie') && typeProgramme,
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom left',
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        analytics: {}
    },
    language: {
        default: "fr-CA",
        autoDetect: "document",
        translations: {
            'en-CA': {
                consentModal: {
                    description: "We place great importance on the protection of your privacy. Our data collection practices are governed by a transparent Privacy Policy. By browsing this site, you consent to the use of cookies and other technologies to enhance your experience and analyze site usage. You have the option to manage your preferences by customizing site or browser settings. You can also adjust your choice at any time by clicking the \"Manage Cookie Preferences\" link located in the footer. Please refer to our <a href=\"/en/privacy-policy/\" class=\"cc-link\">Privacy Policy</a> for more information.",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences"
                },
                preferencesModal: {
                    title: "Cookie preferences",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    closeIconLabel: "Close modal",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            "title": null,
                            "description": "We use cookies to help you navigate efficiently and perform certain functions. Detailed information about all cookies can be found under each consent category below. <br><br>You can choose to enable or disable these cookies in whole or in part. However, please note that disabling some of them may impact your browsing experience."
                        },
                        {
                            title: "Necessary cookies <span class=\"pm__badge\">Always Enabled</span>",
                            description: "Cookies categorized as \"necessary\" are stored on your browser and are essential for the basic functionalities of the site. Crucial for the proper functioning of the site, necessary cookies do not store any personally identifiable information.",
                            linkedCategory: "necessary",
                            cookieTable: {
                                headers: {
                                    "name": "Name",
                                    "description": "Description",
                                    "domain": "Domain",
                                    "expiration": "Expiration"
                                },
                                body: [
                                    {
                                        "name": "_GRECAPTCHA",
                                        "description": "This cookie is set by Google reCAPTCHA, which protects the site against spam enquiries on contact forms.",
                                        "domain": "google.com",
                                        "expiration": "6 months"
                                    },
                                    {
                                        "name": "wp-wpml_current_language",
                                        "description": "This cookie allows saving the user's language preference.",
                                        "domain": window.location.hostname,
                                        "expiration": "1 day"
                                    }
                                ]
                            }
                        },
                        {
                            title: "Optional cookies",
                            description: "We use third-party cookies to analyze your usage of the site. Analytics cookies provide information about visitor interactions, including the number, bounce rate, traffic source, etc. These cookies will only be stored in your browser with your prior consent.",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    "name": "Nom",
                                    "description": "Description",
                                    "domain": "Domaine",
                                    "expiration": "Expiration"
                                },
                                body: [
                                    {
                                        "name": "__Secure-1PAPISID",
                                        "description": "This cookie is used for targeting purposes to build a profile of the website visitor's interests in order to show relevant & personalised Google advertising.",
                                        "domain": "google.com",
                                        "expiration": "2 years"
                                    },
                                    {
                                        "name": "__Secure-1PSID",
                                        "description": "This cookie is used for targeting purposes to build a profile of the website visitor's interests in order to show relevant and personalised Google advertising.",
                                        "domain": "google.com",
                                        "expiration": "2 years"
                                    },
                                    {
                                        "name": "__Secure-1PSIDCC",
                                        "description": "This cookie is used for targeting purposes to build a profile of the website visitor's interests in order to show relevant & personalised Google advertising.",
                                        "domain": "google.com",
                                        "expiration": "1 year"
                                    },
                                    {
                                        "name": "__Secure-1PSIDTS",
                                        "description": "This cookie is used for targeting purposes to build a profile of the website visitor's interests in order to show relevant & personalised Google advertising.",
                                        "domain": "google.com",
                                        "expiration": "1 year"
                                    },
                                    {
                                        "name": "__Secure-3PAPISID",
                                        "description": "This cookie is used for targeting purposes to build a profile of the website visitor's interests in order to show relevant and personalised Google advertising.",
                                        "domain": "google.com",
                                        "expiration": "2 years"
                                    },
                                    {
                                        "name": "__Secure-3PSID",
                                        "description": "This cookie is used for targeting purposes to build a profile of the website visitor's interests in order to show relevant and personalised Google advertising.",
                                        "domain": "google.com",
                                        "expiration": "2 years"
                                    },
                                    {
                                        "name": "__Secure-3PSIDCC",
                                        "description": "This cookie is used by Google to build a profile of website visitor interests to show relevant and personalised ads through retargeting.",
                                        "domain": "google.com",
                                        "expiration": "1 year"
                                    },
                                    {
                                        "name": "_ga",
                                        "description": "This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assigns a randomly generated number to identify unique visitors.",
                                        "domain": "google.com",
                                        "expiration": "2 years"
                                    },
                                    {
                                        "name": "_gid",
                                        "description": "This cookie is installed by Google Analytics. The cookie is used to store information of how visitors use a website and helps in creating an analytics report of how the website is doing. The data collected includes the number of visitors, the source where they have come from, and the pages visited in an anonymous form.",
                                        "domain": "google.com",
                                        "expiration": "1 day"
                                    },
                                    {
                                        "name": "1P_JAR",
                                        "description": "This cookie collects statistics on website usage and measures conversions. A conversion occurs, for example, when a user becomes a buyer. The cookie is also used to display relevant ads to users. Furthermore, the cookie can be used to prevent a user from seeing the same ad more than once.",
                                        "domain": "google.com",
                                        "expiration": "1 month"
                                    },
                                    {
                                        "name": "AEC",
                                        "description": "This cookie is used by Google to ensure that requests within a browsing session are made by the user, and not by other sites.",
                                        "domain": "google.com",
                                        "expiration": "6 months"
                                    },
                                    {
                                        "name": "APISID",
                                        "description": "This cookie is used by Google to display personalised advertisements on Google sites, based on recent searches and previous interactions.",
                                        "domain": "google.com",
                                        "expiration": "1 year"
                                    },
                                    {
                                        "name": "HSID",
                                        "description": "This cookie contains digitally signed and encrypted records of a user’s Google Account ID and most recent sign-in time.",
                                        "domain": "google.com",
                                        "expiration": "2 years"
                                    },
                                    {
                                        "name": "NID",
                                        "description": "This cookie is used to show Google ads in Google services for signed-out users.",
                                        "domain": "google.com",
                                        "expiration": "5 years"
                                    },
                                    {
                                        "name": "OTZ",
                                        "description": "The OTZ cookie is set by Google Analytics and is used to provide an aggregate analysis of website visitors.",
                                        "domain": "google.com",
                                        "expiration": "1 month"
                                    },
                                    {
                                        "name": "SEARCH_SAMESITE",
                                        "description": "This cookie is used for the correct sending of data to Google.",
                                        "domain": "google.com",
                                        "expiration": "5 months"
                                    },
                                    {
                                        "name": "_gcl_au",
                                        "description": "This cookie is used to track the advertising effectiveness of websites using Google's services.",
                                        "domain": "google.com",
                                        "expiration": "3 months"
                                    },
                                    {
                                        "name": "SSID",
                                        "description": "This cookie is used for ad optimization.",
                                        "domain": "google.com",
                                        "expiration": "1 year 1 month 5 days"
                                    },
                                    {
                                        "name": "bcookie",
                                        "description": "Browser Identifier cookie to uniquely identify devices accessing LinkedIn to detect abuse on the platform.",
                                        "domain": ".linkedin.com",
                                        "expiration": "1 year"
                                    },
                                    {
                                        "name": "dfpfpt",
                                        "description": "Unique user identifier to prevent abuse in payment workflows for LinkedIn.",
                                        "domain": ".linkedin.com",
                                        "expiration": "2 years"
                                    },
                                    {
                                        "name": "lang",
                                        "description": "Used to remember a user's language setting to ensure LinkedIn.com displays in the language selected by the user in their settings..",
                                        "domain": ".linkedin.com",
                                        "expiration": "Session"
                                    },
                                    {
                                        "name": "li_gc",
                                        "description": "Used to store consent of guests regarding the use of cookies for non-essential purposes.",
                                        "domain": ".linkedin.com",
                                        "expiration": "6 months"
                                    },
                                    {
                                        "name": "li_mc",
                                        "description": "Used as a temporary cache to avoid database lookups for a member's consent for use of non-essential cookies and used for having consent information on the client side to enforce consent on the client side.",
                                        "domain": ".linkedin.com",
                                        "expiration": "6 months"
                                    },
                                    {
                                        "name": "liap",
                                        "description": "Used by non-www.domains to denote the logged in status of a member.",
                                        "domain": ".linkedin.com",
                                        "expiration": "1 year"
                                    },
                                    {
                                        "name": "lidc",
                                        "description": "To facilitate data center selection.",
                                        "domain": ".linkedin.com",
                                        "expiration": "24 hours"
                                    },
                                    {
                                        "name": "_fbp",
                                        "description": "This cookie is used to distinguish and keep track of our unique users, in order to improve relevance and to serve ads on Facebook.",
                                        "domain": ".facebook.com",
                                        "expiration": "3 months"
                                    },
                                    {
                                        "name": "dpr, wd",
                                        "description": "These cookies store the browser window dimensions and are used by Facebook to optimize the rendering of the page to deliver the optimal experience for your device’s screen.",
                                        "domain": ".facebook.com",
                                        "expiration": "7 days"
                                    },
                                    {
                                        "name": "usida",
                                        "description": "This cookie collects a combination of the user’s browser and unique identifiers that are used for tailoring advertising to users.",
                                        "domain": ".facebook.com",
                                        "expiration": "Session"
                                    },
                                    {
                                        "name": "datr",
                                        "description": "This cookie is used to identify the web browser that was used to connect to Facebook independent of the logged in user and it plays a key role in Facebook’s security and site integrity features.",
                                        "domain": ".facebook.com",
                                        "expiration": "1 year 1 month 5 days"
                                    },
                                ]
                            }
                        },
                        {
                            title: "More information",
                            description: "For any query in relation to my policy on cookies and your choices, please <a class=\"cc__link\" href=\"/en/privacy-policy/\">contact me</a>."
                        }
                    ]
                }
            },
            'fr-CA': {
                consentModal: {
                    description: "Nous attachons une grande importance à la protection de votre vie privée. Nos pratiques de collecte de données sont régies par une Politique de confidentialité transparente. En naviguant sur ce site, vous consentez à l'utilisation de témoins (cookies) et d'autres technologies pour améliorer votre expérience et analyser l'utilisation du site. Vous avez la possibilité de gérer vos préférences en personnalisant les paramètres du site ou de votre navigateur. Vous pouvez également ajuster votre choix à tout moment en cliquant sur le lien \"Gestion des témoins de navigation\", situé dans le pied de page. Consultez notre <a href=\"/politique-de-confidentialite/\" class=\"cc-link\">Politique de confidentialité</a> pour plus d'informations.",
                    acceptAllBtn: "Tout accepter",
                    acceptNecessaryBtn: "Tout rejeter",
                    showPreferencesBtn: "Gérer les préférences"
                },
                preferencesModal: {
                    title: "Personnaliser vos préférences en matière de consentement",
                    acceptAllBtn: "Tout accepter",
                    acceptNecessaryBtn: "Tout rejeter",
                    savePreferencesBtn: "Sauvegarder les préférences",
                    closeIconLabel: "Fermer la modale",
                    serviceCounterLabel: "Services",
                    sections: [
                        {
                            "title": null,
                            "description": "Nous utilisons des témoins (cookies) pour vous aider à naviguer efficacement et à exécuter certaines fonctionnalités. Vous trouverez des informations détaillées sur tous les cookies sous chaque catégorie de consentement ci-dessous. <br><br>Vous pouvez choisir d'activer tout ou de désactiver en partie ces témoins. Toutefois, veuillez noter que la désactivation de certains d'entre eux pourrait avoir un impact sur votre expérience de navigation. "
                        },
                        {
                            title: "Témoins obligatoires <span class=\"pm__badge\">Toujours Activé</span>",
                            description: "Les témoins catégorisés comme \"obligatoires\" sont stockés sur votre navigateur et sont essentiels pour les fonctionnalités de base du site. Cruciaux pour le bon fonctionnement du site, les témoins nécessaires ne stockent aucune donnée personnellement identifiable.",
                            linkedCategory: "necessary",
                            cookieTable: {
                                headers: {
                                    "name": "Nom",
                                    "description": "Description",
                                    "domain": "Domaine",
                                    "expiration": "Expiration"
                                },
                                body: [
                                    {
                                        "name": "_GRECAPTCHA",
                                        "description": "Ce témoin est défini par Google reCAPTCHA, qui protège le site contre les demandes de spam envoyées à travers les formulaires de contact.",
                                        "domain": "google.com",
                                        "expiration": "6 mois"
                                    },
                                    {
                                        "name": "wp-wpml_current_language",
                                        "description": "Ce témoin permet d'enregistrer la langue de l’utilisateur",
                                        "domain": window.location.hostname,
                                        "expiration": "1 jour"
                                    }
                                ]
                            }
                        },
                        {
                            title: "Témoins facultatifs",
                            description: "Nous utilisons des témoins tiers pour analyser votre utilisation du site. Les témoins facultatifs fournissent des informations sur les interactions des visiteurs, notamment le nombre, le taux de rebond, la source de trafic, etc. Ces témoins ne seront stockés dans votre navigateur qu'avec votre consentement préalable.",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    "name": "Nom",
                                    "description": "Description",
                                    "domain": "Domaine",
                                    "expiration": "Expiration"
                                },
                                body: [
                                    {
                                        "name": "__Secure-1PAPISID",
                                        "description": "Ce témoin est utilisé à des fins de ciblage pour construire un profil des centres d'intérêt du visiteur du site web, afin d'afficher une publicité Google pertinente et personnalisée.",
                                        "domain": "google.com",
                                        "expiration": "2 ans"
                                    },
                                    {
                                        "name": "__Secure-1PSID",
                                        "description": "Ce témoin est utilisé à des fins de ciblage afin de construire un profil des centres d'intérêt du visiteur du site web, afin d'afficher une publicité Google pertinente et personnalisée.",
                                        "domain": "google.com",
                                        "expiration": "2 ans"
                                    },
                                    {
                                        "name": "__Secure-1PSIDCC",
                                        "description": "Ce témoin est utilisé à des fins de ciblage afin de créer un profil des centres d'intérêt du visiteur du site web, dans le but d'afficher une publicité Google pertinente et personnalisée.",
                                        "domain": "google.com",
                                        "expiration": "1 an"
                                    },
                                    {
                                        "name": "__Secure-1PSIDTS",
                                        "description": "Ce témoin est utilisé à des fins de ciblage afin de créer un profil des centres d'intérêt du visiteur du site web, dans le but d'afficher une publicité Google pertinente et personnalisée.",
                                        "domain": "google.com",
                                        "expiration": "1 an"
                                    },
                                    {
                                        "name": "__Secure-3PAPISID",
                                        "description": "Ce témoin est utilisé à des fins de ciblage pour créer un profil des centres d'intérêt du visiteur du site web, dans le but d'afficher une publicité Google pertinente et personnalisée.",
                                        "domain": "google.com",
                                        "expiration": "2 ans"
                                    },
                                    {
                                        "name": "__Secure-3PSID",
                                        "description": "Ce témoin est utilisé à des fins de ciblage pour créer un profil des centres d'intérêt du visiteur du site web, dans le but d'afficher une publicité Google pertinente et personnalisée.",
                                        "domain": "google.com",
                                        "expiration": "2 ans"
                                    },
                                    {
                                        "name": "__Secure-3PSIDCC",
                                        "description": "Ce témoin est utilisé par Google pour créer un profil des centres d'intérêt du visiteur du site web, afin d'afficher des publicités pertinentes et personnalisées grâce au reciblage publicitaire.",
                                        "domain": "google.com",
                                        "expiration": "1 an"
                                    },
                                    {
                                        "name": "_ga",
                                        "description": "Google Analytics utilise ce témoin pour calculer les données relatives aux visiteurs, aux sessions et aux campagnes. Le témoin permet de suivre l’utilisation du site pour le rapport d’analyse du site. Il stocke les informations de manière anonyme et attribue un numéro généré de manière aléatoire pour identifier les visiteurs uniques.",
                                        "domain": "google.com",
                                        "expiration": "2 ans"
                                    },
                                    {
                                        "name": "_gid",
                                        "description": "Ce témoin est installé par Google Analytics. Il sert à stocker des informations sur la façon dont les visiteurs utilisent un site web et aide à créer un rapport analytique sur les performances du site. Les données recueillies incluent le nombre de visiteurs, leur source d'origine, ainsi que les pages visitées, le tout de manière anonyme.",
                                        "domain": "google.com",
                                        "expiration": "1 jour"
                                    },
                                    {
                                        "name": "1P_JAR",
                                        "description": "Ce témoin collecte des statistiques sur l'utilisation du site web et mesure les conversions. Une conversion se produit, par exemple, lorsqu'un utilisateur devient un acheteur. Le témoin est également utilisé pour afficher des publicités pertinentes aux utilisateurs. De plus, le témoin peut être utilisé pour éviter qu'un utilisateur ne voie la même publicité plus d'une fois.",
                                        "domain": "google.com",
                                        "expiration": "1 mois"
                                    },
                                    {
                                        "name": "AEC",
                                        "description": "Ce témoin est utilisé par Google pour s'assurer que les requêtes effectuées au cours d'une session de navigation proviennent de l'utilisateur et non d'autres sites.",
                                        "domain": "google.com",
                                        "expiration": "6 mois"
                                    },
                                    {
                                        "name": "APISID",
                                        "description": "Ce témoin est utilisé par Google pour afficher des publicités personnalisées sur les sites de Google, en se basant sur les recherches récentes et les interactions précédentes de l'utilisateur.",
                                        "domain": "google.com",
                                        "expiration": "1 an"
                                    },
                                    {
                                        "name": "HSID",
                                        "description": "Ce témoin contient des enregistrements numériquement signés et chiffrés de l'identifiant du compte Google d'un utilisateur ainsi que de l'heure de sa dernière connexion.",
                                        "domain": "google.com",
                                        "expiration": "2 ans"
                                    },
                                    {
                                        "name": "NID",
                                        "description": "Ce témoin est utilisé pour afficher des publicités Google dans les services Google pour les utilisateurs non connectés.",
                                        "domain": "google.com",
                                        "expiration": "5 mois"
                                    },
                                    {
                                        "name": "OTZ",
                                        "description": "Le témoin OTZ est défini par Google Analytics et est utilisé pour fournir une analyse globale des visiteurs du site web.",
                                        "domain": "google.com",
                                        "expiration": "1 mois"
                                    },
                                    {
                                        "name": "SEARCH_SAMESITE",
                                        "description": "Ce témoin est utilisé pour l'envoi correct des données à Google.",
                                        "domain": "google.com",
                                        "expiration": "5 mois"
                                    },
                                    {
                                        "name": "_gcl_au",
                                        "description": "Ce témoin est utilisé pour suivre l'efficacité publicitaire des sites web utilisant les services de Google.",
                                        "domain": "google.com",
                                        "expiration": "3 mois"
                                    },
                                    {
                                        "name": "SSID",
                                        "description": "Ce témoin est utilisé dans l'optimisation des publicités.",
                                        "domain": "google.com",
                                        "expiration": "1 an 1 mois 5 jours"
                                    },
                                    {
                                        "name": "bcookie",
                                        "description": "Cookie de navigateur permettant d’identifier chaque appareil accédant à LinkedIn afin de détecter tout abus sur la plateforme et à des fins de diagnostic.",
                                        "domain": ".linkedin.com",
                                        "expiration": "1 an"
                                    },
                                    {
                                        "name": "dfpfpt",
                                        "description": "Identifiant d’utilisateur unique pour prévenir les abus dans les processus de paiement pour LinkedIn.",
                                        "domain": ".linkedin.com",
                                        "expiration": "2 ans"
                                    },
                                    {
                                        "name": "lang",
                                        "description": "Utilisé pour mémoriser le paramètre de langue d’un utilisateur afin de garantir que LinkedIn.com s’affiche dans la langue sélectionnée par l’utilisateur dans ses préférences.",
                                        "domain": ".linkedin.com",
                                        "expiration": "Session"
                                    },
                                    {
                                        "name": "li_gc",
                                        "description": "Utilisé pour stocker le consentement des utilisateurs invités concernant l’utilisation de cookies à des fins non essentielles.",
                                        "domain": ".linkedin.com",
                                        "expiration": "6 mois"
                                    },
                                    {
                                        "name": "li_mc",
                                        "description": "Utilisé comme cache temporaire pour éviter les recherches sur le consentement de l’utilisateur dans la base de données pour l’utilisation de cookies non essentiels, et utilisé pour obtenir des données de consentement de la part du client afin d’appliquer son consentement.",
                                        "domain": ".linkedin.com",
                                        "expiration": "6 mois"
                                    },
                                    {
                                        "name": "liap",
                                        "description": "Utilisé par les sites autres que “www.domaines” pour indiquer le statut de connexion d’un membre.",
                                        "domain": ".linkedin.com",
                                        "expiration": "1 an"
                                    },
                                    {
                                        "name": "lidc",
                                        "description": "Pour optimiser la sélection des centres de données.",
                                        "domain": ".linkedin.com",
                                        "expiration": "24 heures"
                                    },
                                    {
                                        "name": "_fbp",
                                        "description": "Ce témoin est utilisé pour distinguer et suivre nos utilisateurs uniques, afin d'améliorer la pertinence et de diffuser des annonces sur Facebook.",
                                        "domain": ".facebook.com",
                                        "expiration": "3 mois"
                                    },
                                    {
                                        "name": "dpr, wd",
                                        "description": "Ces témoins stockent les dimensions de la fenêtre du navigateur et sont utilisés par Facebook pour optimiser l'affichage de la page afin de fournir l'expérience optimale pour l'écran de votre appareil.",
                                        "domain": ".facebook.com",
                                        "expiration": "7 jours"
                                    },
                                    {
                                        "name": "usida",
                                        "description": "Ce témoin collecte une combinaison d'identifiants uniques et de données du navigateur de l'utilisateur pour adapter la publicité aux utilisateurs.",
                                        "domain": ".facebook.com",
                                        "expiration": "Session"
                                    },
                                    {
                                        "name": "datr",
                                        "description": "Ce témoin est utilisé pour identifier le navigateur web utilisé pour se connecter à Facebook indépendamment de l'utilisateur connecté, et joue un rôle clé dans les fonctionnalités de sécurité et d'intégrité du site de Facebook.",
                                        "domain": ".facebook.com",
                                        "expiration": "1 an 1 mois 5 jours"
                                    }
                                ]
                            }
                        },
                        {
                            title: "Pour en savoir plus",
                            description: "Pour plus d’information, consultez notre <a class=\"cc-link\" href=\"/politique-de-confidentialite/\">Politique de confidentialité</a>."
                        }
                    ]
                }
            }
        }
    },
});


document.addEventListener('DOMContentLoaded', () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('showCookiePreference')) {
        CookieConsent.showPreferences();
        // Clear the showCookiePreference parameter from the URL
        urlParams.delete('showCookiePreference');
        const newUrl = `${window.location.pathname}${urlParams.toString()}`;
        history.replaceState({}, '', newUrl);
    }
});
