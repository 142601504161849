import './side-panel';

const $offCanvas = document.querySelector('.side-panel .side-panel-job');
const $nameInput = document.querySelector('.side-panel .side-panel-job .first-name-input');
const $lastNameInput = document.querySelector('.side-panel .side-panel-job .last-name-input');
const $postalCodeInput = document.querySelector('.side-panel .side-panel-job .postal-code-input');
const $countryInput = document.querySelector('.side-panel .side-panel-job .country-select');
const $fileUpload = document.querySelector('.side-panel .side-panel-job .file-input');
const $fileUploadName = document.querySelector('.side-panel .side-panel-job .file-upload-name');
const $btnJobSubmit = document.querySelector('.side-panel .side-panel-job .btn-job-submit');

if ($offCanvas) {
    // Enabled submit button if all required fields are not empty
    const updateSubmitButton = () => {
        const inputs = [$nameInput, $lastNameInput, $postalCodeInput, $countryInput, $fileUpload];
        const allInputsFilled = inputs.every((input) => input.value.trim() !== '');

        $btnJobSubmit.disabled = !allInputsFilled;
    };

    const updateFileName = () => {
        if ($fileUpload.files.length > 0) {
            const fileName = $fileUpload.files[0].name;
            $fileUploadName.innerHTML = fileName;
        } else {
            $fileUploadName.innerHTML = $fileUploadName.getAttribute('data-text');
        }
    };

    const inputs = [$nameInput, $lastNameInput, $postalCodeInput, $countryInput, $fileUpload];
    inputs.forEach((input) => {
        input.addEventListener('input', updateSubmitButton);
    });

    $fileUpload.addEventListener('change', updateFileName);
}
