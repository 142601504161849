import './side-panel';

const $contactForm = document.querySelector('.side-panel .side-panel-contact');
const $nameInput = document.querySelector('.side-panel .side-panel-contact .first-name-input');
const $lastNameInput = document.querySelector('.side-panel .side-panel-contact .last-name-input');
const $messageInput = document.querySelector('.side-panel .side-panel-contact .message-input');
const $btnContactSubmit = document.querySelector('.side-panel .side-panel-contact .btn-submit-contact');

if ($contactForm) {
    // Enabled submit button if first name, last name and message are not empty
    const updateSubmitButton = () => {
        if ($nameInput.value.trim() !== '' && $lastNameInput.value.trim() !== '' && $messageInput.value.trim() !== '') {
            $btnContactSubmit.removeAttribute('disabled');
        } else {
            $btnContactSubmit.setAttribute('disabled', 'true');
        }
    };

    const inputs = [$nameInput, $lastNameInput, $messageInput];
    inputs.forEach((input) => {
        input.addEventListener('input', updateSubmitButton);
    });
}
