// Formating phone number
const $phoneInput = document.querySelector('.side-panel-job .phone-input');

if ($phoneInput) {
    // Force phone number format 1 (xxx) xxx-xxxx
    $phoneInput.addEventListener('input', (e) => {
        const x = e.target.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : `${x[1]} (${x[2]}) ${x[3]}${x[4] ? `-${x[4]}` : ''}`;
    });
}
