const $wpcf7Forms = document.querySelectorAll('.wpcf7');

$wpcf7Forms.forEach(($wpcf7Elm) => {
    $wpcf7Elm.addEventListener('wpcf7submit', (event) => {
        const submittedForm = event.target;
        if (event.detail.status.includes('fail')) {
            submittedForm.querySelector('.wpcf7-response-output').style.removeProperty('display');
        } else {
            const confirmationBlock = submittedForm.closest('.offcanvas-body').querySelector('.confirmation-block');
            submittedForm.querySelectorAll('.upload-btn').forEach((input) => {
                input.querySelector('.file-input').value = '';
                input.querySelector('.file-upload-name').innerHTML = input.querySelector('.file-upload-name').getAttribute('data-text');
            });
            submittedForm.querySelector('button').setAttribute('disabled', 'true');
            submittedForm.querySelector('.wpcf7-response-output').style.display = 'none';
            submittedForm.style.display = 'none';
            confirmationBlock.style.removeProperty('display');
        }
    });
});
